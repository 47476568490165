.modern-radio-container {
    margin: 24px 0;
    display: flex;
    cursor: pointer;
    user-select: none;
}

.radio-outer-circle {
    margin-top: 0.3em;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border: 2px solid purple;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    transition: all 0.1s linear;
}

.radio-inner-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: purple;
    transition: all 0.1s linear;
}
.unselected {
    text-align: center;
}
.unselected-circle {
    width: 0;
    height: 0;
}
.helper-text {
    color: black;

}
