.shop-header {
    margin-top:0.5rem;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    /*border-bottom: 3px solid orange;*/
}

.shop-header a{
    text-decoration: none;
}

.shop-header .logo {
    /*font-family: "Playfair Display", Georgia, serif;
    font-size: 2.5rem;*/
    padding-left: 1rem;
}

.shop-header .logo-text{
    padding-left: 1rem;
    font-size: 0.7rem;
    color:purple;
}

.shop-header .shopping-cart {
    padding-top: 0.75rem;
    /*align-self: baseline;*/
    /*font-size: 1.3rem;*/
    padding-right: 1rem;
}

.shop-header .shopping-cart .cart-icon {
    font-size: 2.2rem;
    color: purple;
    /*margin-right: 10px;*/
    padding-top: 10px;
}

.shop-header .shopping-cart .numItems{
    padding-top: 0;
    margin-top:0;
    font-size: 1rem;
    color: purple;
    border-radius: 0.75rem;
    background: orange;
    text-align: center;
    height: 1.5rem;
    width: 1.5rem;
    justify-self: center;


    margin-left: -0.55rem;
}


.logo > img {
    width: 10rem;
}

.phone, .dropdown {
    align-self: center;
}

.phone i {
    font-size: 1.25rem;
    color: purple;
    margin-top: 0;
}

.header-line{
    width: 200%;
    background: orange;
    padding: 0;
    margin: 0;

}

