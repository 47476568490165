.card-of-product {
    padding-top: 1rem;
}

.card-of-product .fa-chevron-left {
    padding-top: 0.3rem;
}

.card-of-product .back-button {
    color: purple;
    margin-bottom: 1rem;
    cursor: pointer;
}

.card-of-product .back-button:hover {
    /*box-shadow: 1px -1px rgba(128, 0, 128, 0.5);*/
    color:orange;
}

.card-of-product .product-info-banner .carousel.carousel-slider .control-arrow:hover {
    /*background: rgba(128,0,128,0.5);*/
    /*background-color: rgba(255, 165, 0, 0.5);*/
    background-color: white;
}

.card-of-product .product-info-banner .carousel.carousel-slider .control-arrow {
    color: purple;
    margin-bottom: 2.21rem;
}

.card-of-product .product-info-banner .carousel .control-prev.control-arrow:before {
    border-right: 8px solid purple;
}

.card-of-product .product-info-banner .carousel .control-next.control-arrow:before {
    border-left: 8px solid purple;

}

.card-of-product .product-info-banner .carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid white;
}


.card-of-product .product-info-banner .carousel .control-dots .dot {
    top: 3rem;
    background-color: purple;
}

.card-of-product .product-info-banner .carousel .slide {
    padding-bottom: 2rem;
}

.card-of-product .product-info-banner img {
    width: 90% !important;
}

.card-of-product .product-info-banner ul.thumbs {
    display: none;
}


.product-info-banner p.carousel-status {
    display: none;
}

/* Carousel for dop items */

.card-of-product .carousel-for-dopItems .carousel.carousel-slider .control-arrow:hover {
    background-color: white;
}

.card-of-product .carousel-for-dopItems .carousel.carousel-slider .control-arrow {
    color: purple;
    margin-bottom: 2.21rem;
}


.card-of-product .carousel-for-dopItems .carousel .control-prev.control-arrow:before {
    border-right: 8px solid purple;
}

.card-of-product .carousel-for-dopItems .carousel .control-next.control-arrow:before {
    border-left: 8px solid purple;

}

.card-of-product .carousel-for-dopItems .carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid white;
}


.card-of-product .carousel-for-dopItems .carousel .control-dots .dot {
    top: 3rem;
    background-color: purple;
}

.card-of-product .carousel-for-dopItems .carousel .slide {
    padding-bottom: 2rem;
}
