.privacy-container .listNumber  {
    list-style-type: dics;
    font-size: 1.7em;
    margin-left: 1em;
}



.privacy-container .justify-text-in-div{
    text-align:justify;
    width: 100%;
    font-size: 1.3em;
}


.privacy-container p{
    margin-top: 0em;
    margin-bottom: 0.5em;
    line-height: 1.5;
    font-size: 1.7em;
}
.privacy-container b{
    margin-top: 0em;
    margin-bottom: 0.5em;
    line-height: 1.5;
    font-size: 1.5rem;
}

.privacy-container span{
    font-size:1.3em;
}
.privacy-container .test{
    width:100%;
    height:1em;
    display:inline-block;
}


.privacy-container .ul-list3{
    font-size: 1.28rem;
    list-style: disc;
    text-align: left;
    padding-left: 1.28em;
    margin-bottom: 0.5em;
}

@media (max-width: 479px)
{
    .privacy-container .ul-list{
        font-size: 16px;
    }
}


.privacy-container .ul-list>li::before{
    content: "\2022";
    color:black;
    list-style-type:circle;
    font-weight:bold;
    display: inline-block;
    width: 1em;
}