
.custom-select-container {
    display: inline-block;
    min-width: 70px;

    position: relative;
    border: solid 2px #b194c0;
    border-radius: 5px;

    z-index: 10;
}

.selected-text {
    background-color: white;
    padding: 6px 0px;
    padding-left: 10px;
    border-radius: 5px;
}

.selected-text::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 16px;
    border: 7px solid transparent;
    border-color: purple transparent transparent transparent;
}

.selected-text.active123::after {
    top: 8px;
    border-color: transparent transparent purple transparent;
}


ul {
    margin: 0;
    padding: 0;
    text-align: center;
}

.select-options {
    position: absolute;
    border: 2px solid rgb(196, 194, 194);

    width: 100%;
}

.liList {
    text-align: left;
    list-style-type: none;
    line-height: 1;
    text-align: left;
    padding-left: 10px;
    background: white;

    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;

}

.liList:hover {
    background-color: #ff9933;
    color: #ffffff;
}