
.product-list-item {
    display: flex;
    margin: 0;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    color: black;
}

a.product-list-item:hover{
    text-decoration: none;
    color: black;
    /*border: 1px solid purple;*/
    border-radius: 20px;
    box-shadow: 1px 1px 1px 1px rgba(128,0,128,0.2);
}
.product-list-item .product-cover {
    flex-shrink: 0;
    width: 100%;
    /*margin-right: 30px;*/
}

.product-list-item .product-cover img{
    max-width: 100%;
    align-self: flex-start;
    background-color: white;
    height: 100%;
    width: auto;
    border-radius: 30px;
}

.product-list-item .product-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 10px;
    align-items: flex-start;
}

.product-list-item .product-details .product-title {
    font-size: 1.2rem;
    height: 5.1rem;
}

.product-list-item .product-details .product-price {
    font-size: 1.4rem;
    justify-self: flex-end;
}

