.cart-container {

}

.cart-container .background {
    z-index: 100;
    background: rgba(128, 128, 128, 0.4);
    position: absolute;
    height: 100%;
    width: 100%;
}

.cart-container .cart {
    padding: 1rem 2rem 2rem;
    background: white;
    position: absolute;
    z-index: 500;
    border: 3px solid orange;
    top: 15%;
    /*height: 70%;*/
    max-height: 70%;
    width: 30%;
    left: 35%;
    min-height: 20%;

    overflow: auto;
}

.cart-container .cart-header {
    display: flex;
    border-bottom: 1px solid orange;
    /*justify-content: space-between;*/
}

.cart-container .cart-header .header-text {
    text-align: center;
    color: purple;
    font-size: 2rem;
}

.cart-container .cart-header .header-button .close-button {
    float: right;
    font-size: 2rem;
    color: purple;
}


.cart-container .cart .last-line {
    display: flex;
    justify-content: space-between;
    margin: 1% 2% 0;
    align-items: center;
}

.cart-container .cart .last-line .continue {
    color: purple;
    text-decoration: underline;
    display: flex;
}

.cart-container .cart .last-line .continue:hover {
    font-weight: bold;
    cursor: pointer;
}

.cart-container .cart .last-line .buy {
    display: flex;
}

.cart-container .cart .last-line .buy .btn {
    background-color: orange;
    color: purple;
}

.cart-container .cart .last-line .buy .btn:hover {
    font-weight: bold;
}
