.check-out-container {

}

.check-out-container .purple-text {
    color: purple;
}

.check-out-container
.check-out-container .main-form .left-block{
    margin-top: 1rem;
}
.check-out-container .main-form .left-block .number{
    /*Менять эти парой и не забыть поменять line-height в box-header -> purple-text*/
    width: 2.5rem;
    height: 2.5rem;


    /*line-height: 2rem;*/
    background: orange;
    /*margin: 1em;*/
    border-radius: 50%;
    text-align: center;
}

.check-out-container .main-form .left-block .box-header .purple-text{
    line-height: 2.5rem;
    font-size: 1.5rem;
}

.check-out-container .main-form .left-block .box-header .header-text{
    padding-left: 1rem;
    font-weight: normal;
}

.check-out-container .main-form .left-block .form-group{
    margin:0;
}
.check-out-container .main-form .left-block .form-group label{
    margin:0;
}

.check-out-container .main-form .left-block .modern-radio-container{
    margin-top: 0;
}


.check-out-container .main-form .right-block{

}
.check-out-container .main-form .right-block .shopping-cart{
    border: 1px solid orange;
}
/*
.check-out-container .main-form .right-block .shopping-cart .item-row .col-1:last-child{
    padding-right: 5rem;
}*/

.check-out-container .main-form .right-block .shopping-cart .item-row{
    margin-right: 1rem;
    margin-left:0rem;
}

.check-out-container .main-form .left-block .block-2 .modern-radio-container{
    margin:0;
}

.check-out-container .main-form .right-block .delivery{
    width:100%;
    margin-top:1rem;
}

.check-out-container .main-form .right-block .delivery .custom-select-container{
    width:100%;
}

.check-out-container .main-form .right-block .delivery .select-options{
    overflow-y:scroll;
    overflow-x:auto;
    max-height: 20rem;
}