.rigth-textblock-container-footer {
    color: purple;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.footer-logo-text{
    padding: 0 0 0 0.1rem;
    margin:0;
    font-size: 0.7rem;
    color:purple;
}

.rigth-textblock-container-footer a{
    color: purple;
}

.cursor-not-allowed{
    cursor: not-allowed;
}