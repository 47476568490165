.product-list{
    /*justify-content: space-around;*/
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

}

.top-product-cat{
    color:purple;
    font-size: 1.1rem;
    margin:0;
    padding: 0;

    margin-top: 1.1rem;
}

.show-cat-items-link{
    font-weight: bold;
    float:right;
    color:orange;
    margin:0;
    padding:0;
}
.show-cat-items-link:hover{
    text-decoration: underline solid orange;
    cursor: pointer;
}


.container-sort-type .custom-select-container{
    width: 13rem;
}
.container-sort-type{
    display: flex;
    justify-content: flex-end;
}