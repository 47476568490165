.shopping-cart-table {
  margin-top: 1rem;
}

.shopping-cart-table .line{
  border-top: 1px solid orange;
  /*height: 1rem;*/
}

.shopping-cart-table .two-lines{
  max-height: 20rem;
  overflow-y: auto;
}


.shopping-cart-table .item-row{
  margin-bottom: 1rem;
}

.shopping-cart-table .total {
  text-align: right;
  font-size: 2rem;
  margin-right: 10px;
  color:purple;
}

.shopping-cart-table button {
  /*margin-left: 5px;*/
  margin-top: 5px;
}

.shopping-cart-table .right-panel .descript{
  padding-top: 1rem;
}

.shopping-cart-table .right-panel .count{
  color:purple;
  font-size: 1.2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
  display: flex;
  align-self: center;
}

.shopping-cart-table .right-panel .btn {
  font-weight: bold;
  background-color: orange;
  color:purple;
}

.shopping-cart-table .right-panel .btn-delete {
  font-weight: normal;
  background-color: transparent;
  color:grey;
  margin-left: 1rem;
}