
.main-banner-carousel .carousel.carousel-slider .control-arrow:hover {
    /*background: rgba(128,0,128,0.5);*/
    /*background-color: rgba(255, 165, 0, 0.5);*/
    background-color: white;
}

.main-banner-carousel .carousel.carousel-slider .control-arrow {
    color: purple;
    margin-bottom: 2.21rem;
}

.main-banner-carousel .carousel .control-prev.control-arrow:before {
    border-right: 8px solid purple;
}

.main-banner-carousel .carousel .control-next.control-arrow:before {
    border-left: 8px solid purple;

}

.main-banner-carousel .carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid white;
}


.main-banner-carousel .carousel .control-dots .dot {
    top: 3rem;
    background-color: purple;
}

.main-banner-carousel .carousel .slide {
    padding-bottom: 2rem;
}

.main-banner-carousel img {
    width: 90% !important;
}

.main-banner-carousel ul.thumbs {
    display: none;
}



.main-banner-carousel .buttonStyle1
{
    background-color: orange;
    border: none;
    color: purple;
}
.main-banner-carousel .buttonStyle1:focus
{
    border: none !important;
    color: purple;
    box-shadow: 0 0 0 0 !important;
}
.main-banner-carousel .buttonStyle1:active
{
    background-color: purple !important;
    border: none !important;

}
.main-banner-carousel .buttonStyle1:hover
{
    background-color: purple;
    color:orange;
    border: none;

}

